import bbobHTML from "@bbob/html";
import { createPreset } from '@bbob/preset';
import TagNode from "@bbob/plugin-helper/es/TagNode";
import { isStringNode } from "@bbob/plugin-helper/es";
import defaultTags from "@bbob/preset-html5/lib/defaultTags";

// ренденр текста с bb
export function renderMsg(msg) {
  const options = { onlyAllowTags: ['i', 'b', 's', 'u', 'img', 'br', 'teremok'] }
  const processed = bbobHTML(
    msg,
    [createPreset(expandedTags)(), linkParsePlugin],
    options
  )
  return processed
}

export function renderMsgAsText(msg) {
  if (msg) {
    return renderMsg(msg).replaceAll(/(<([^>]+)>)/gi, "")
  } else {
    return ''
  }
}

// регулярка для тестирования URL адресса
// https://urlregex.com
const URL_RE = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/g

const isValidUrl = (url) => URL_RE.test(url);

// автоматически парсим ссылки в <a href="url">Ссылка</a>
const linkParsePlugin = (tree) => {
  return tree.walk((node) => {
    if (isStringNode(node) && isValidUrl(node)) {
      return TagNode.create(
        "a",
        {
          href: node
        },
        `Ссылка`
      );
    }

    return node;
  });
};

// расширение для тэгов
const expandedTags = {
  ...defaultTags,
  // добавляем <br>
  // к сожалению, @bbob всегда автоматически добавляет
  // и открывающий, и закрываюзщий тэги
  br: function(node) {
    return { tag: 'span', style: '', content: '</br>' }
  },
  teremok: function(node) {
    if (Object.hasOwn(node.attrs, 'place')) {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return {
        tag: 'a',
        attrs: {
          href: `${window.location.origin}/place/${node.attrs.place}` +
            `?children=0&guests=2` +
            `&date_from=${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}` +
            `&date_to=${tomorrow.getFullYear()}-${('0' + (tomorrow.getMonth() + 1)).slice(-2)}-${('0' + tomorrow.getDate()).slice(-2)}`,
          id: `teremok-chat-link`,
        },
        content: node.content
      }
    }
  }
}